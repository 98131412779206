import React from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'
import GoogleReviews from '../components/google_reviews'
import Seo from '../components/seo'

const EntryPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
  const side_image = getImage(entry.frontmatter.side_image)
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <div className="w-full pt-24 pb-24 lg:pt-24 lg:pb-24 bg-white relative">
        <div className="absolute -inset-0">
          <GatsbyImage image={image} alt={entry.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
        </div>
        <div className="absolute -inset-0 bg-black opacity-25"></div>
        <div className="absolute -inset-0 opacity-1 bg-stardust"></div>
        <div className="w-11/12 sm:w-6/12 mx-auto max-w-screen-xl relative">
          <div className="flex justify-around items-center text-center">
            <div>
              <h1 className="font-shoulders uppercase font-bold text-white text-6xl lg:text-8xl leading-none">{entry.frontmatter.heading}</h1>
              <div className="font-verdana uppercase bg-stardust tracking-wide font-bold text-white mt-6 mr-0 lg:mr-6 bg-blue py-3 px-6 inline-block">
                <a href="tel:0800742771" title="0800 742 771" rel="noopener nofollow">Call 0800 742 771</a>
              </div>
              <div className="font-verdana uppercase bg-stardust tracking-wide font-bold text-white mt-3 bg-black py-3 px-6 inline-block">
                <Link to="/contact/">Make enquiry</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12 pt-12 border-b-2 border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="font-verdana prose leading-relaxed">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="font-verdana uppercase bg-stardust text-xl tracking-wide font-bold text-white mt-6 mr-6 bg-blue py-3 px-6 inline-block">
                <a href="tel:0800742771" title="0800 742 771" rel="noopener nofollow">Call 0800 742 771</a>
              </div>
              <div className="font-verdana uppercase bg-stardust text-xl tracking-wide font-bold text-white mt-3 bg-black py-3 px-6 inline-block">
                <Link to="/contact/">Make enquiry</Link>
              </div>
            </div>
            <div className="w-full sm:w-4/12 pt-12 sm:pt-6">
              {side_image !== null && 
              <figure className="mb-12">
                <GatsbyImage className="aspect-w-1 aspect-h-1" image={side_image} alt={entry.frontmatter.side_heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
              </figure>
              }
              {entry.frontmatter.side_heading !== null &&
              <div className="font-verdana font-bold text-dark-grey text-xl">
                <h3>{entry.frontmatter.side_heading}</h3>
              </div>
              }
              {entry.frontmatter.side_text !== null && 
              <div className="font-verdana text-base text-grey-6 mt-6">
                <ul>
                {entry.frontmatter.side_text.map((entry, i) => {
                  return (
                    <li key={`side_text_${i}`} className="mb-2"><svg xmlns="http://www.w3.org/2000/svg" className="text-blue inline h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg> {entry.text}</li>
                  )
                })}
                </ul>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <GoogleReviews />
      <Footer />
    </div>
	)
}

export default EntryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        side_image {
					childImageSharp {
            gatsbyImageData(width: 640)
          }
				}
        side_heading
        side_text {
          text
        }
			}
			fields {
				slug
			}
      html
		}
	}
`